import _ from 'lodash'

export const ROLE = {
  Player: 'player',
  Stringer: 'stringer',
  Admin: 'admin',
  SuperUser: 'superuser',
  SiteOwner: 'siteowner'
}

export const PAYMENT_METHOD = {
  Online: {
    code: 'stripe',
    name: 'Card',
    icon: 'card'
  },
  InStore: {
    code: 'eft',
    name: 'In-store',
    icon: 'location'
  },
  PrizeMoney: {
    code: 'prize',
    name: 'Prize money',
    icon: 'prize'
  }
}

export const STATUS = {
  Open: {
    name: 'open',
    index: 1,
    badgeColor: '#F4D6ED',
    dotColor: '#A1007D'
  },
  InProgress: {
    name: 'in progress',
    index: 2,
    badgeColor: '#F4F3D6',
    dotColor: '#9DA100'
  },
  Complete: {
    name: 'complete',
    index: 3,
    badgeColor: '#D6E0F4',
    dotColor: '#0054A1'
  },
  PickedUp: {
    name: 'picked up',
    index: 4,
    badgeColor: '#D6F4DF',
    dotColor: '#00A154'
  },
  Cancelled: {
    name: 'cancelled',
    index: 5,
    badgeColor: '#F0E0E0',
    dotColor: '#A34347'
  }
}

export const parseStatus = (index, cancelled) => {
  if (cancelled) {
    index = 5
  }
  for (const x in STATUS) {
    if (STATUS[x].index === index) {
      return {
        name: _.capitalize(STATUS[x].name),
        badgeColor: STATUS[x].badgeColor,
        dotColor: STATUS[x].dotColor
      }
    }
  }
  return 'Invalid status'
}

export const playerTypes = [
  {
    title: 'ATP Male',
    prefix: 'ATP'
  },
  {
    title: 'WTA Female',
    prefix: 'WTA'
  },
  {
    title: 'ITF Junior',
    prefix: 'ITF'
  },
  {
    title: 'ITF Wheelchair',
    prefix: 'ITF'
  },
  {
    title: 'Other',
    prefix: 'OTH'
  }
]

export const parsePlayerIdPrefix = playerType => {
  if (isNaN(playerType)) {
    return ''
  }
  return playerTypes[playerType - 1].prefix
}
