import { omit } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Col, Row, Table } from 'react-bootstrap'
import TournamentDropdown from '../common/TournamentDropdown'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import useDataService from '../../hooks/useDataService'

const RacquetsReport = () => {
  const { tournament } = useContext(SharedDataContext)
  const { apiGetRacquetsReport } = useDataService()
  const [selectedTournament, setSelectedTournament] = useState(tournament)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    if (selectedTournament) {
      apiGetRacquetsReport(selectedTournament._id).then(res => {
        setData(res)
        setLoading(false)
      })
    }
  }, [selectedTournament])

  const prepareData = (data) => {
    return data.map(r => omit(r, ['_id', 'tournament', 'orderDate']))
  }

  const headers = [
    { label: 'PlayerName', key: 'player' },
    { label: 'Quantity', key: 'quantity' },
    { label: 'Total', key: 'racquetTotal' },
    { label: 'RRPTotal', key: 'rrpTotal' },
    { label: 'Grip', key: 'overgrip' },
    { label: 'Service', key: 'service' },
    { label: 'Discount', key: 'discount' },
    { label: 'Main.Strings.OwnStrings', key: 'main.strings.ownStrings' },
    { label: 'Main.Strings.Brand', key: 'main.strings.brand' },
    { label: 'Main.Strings.Model', key: 'main.strings.model' },
    { label: 'Main.Tension', key: 'main.tension' },
    { label: 'Cross.Strings.OwnStrings', key: 'cross.strings.ownStrings' },
    { label: 'Cross.Strings.Brand', key: 'cross.strings.brand' },
    { label: 'Cross.Strings.Model', key: 'cross.strings.model' },
    { label: 'Cross.Tension', key: 'cross.tension' },
    { label: 'Paid', key: 'paid' },
    { label: 'TensionUnits', key: 'tensionUnits' }
  ]

  return (
    <>
      <Row className='mx-0 px-1 pb-3 mb-3 border-bottom justify-content-between align-items-end'>
        <Col xs='auto'>
          <h3>Restrung racquets report</h3>
        </Col>
        <Col xs='auto'>
          <CSVLink filename='restrung-racquets.csv' className={`btn btn-primary ${loading ? 'disabled' : ''}`} data={prepareData(data)} headers={headers}>Get csv</CSVLink>
        </Col>
      </Row>
      <Row className='mx-0 px-1 gy-1 mb-3 w-md-50'>
        <Col xs={12}>
          <TournamentDropdown value={selectedTournament} handleSelect={(t) => setSelectedTournament(t)} />
        </Col>
        <Col xs={12}>
          <p>The data below is a preview from {selectedTournament.name}.<br/>Please click "Get CSV" to view the entire data.</p>
        </Col>
      </Row>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th className='border-left-0'>Player Name</th>
            <th>Quantity</th>
            <th>Total</th>
            <th>RRP Total</th>
            <th>Discount</th>
            <th>Service</th>
            <th>Overgrip</th>
            <th className='border-right-0'>Paid</th>
          </tr>
        </thead>
        <tbody>
          {data.slice(0, 10).map(r => (
            <tr key={r._id}>
              <td className='border-left-0'>{r.player}</td>
              <td>{r.quantity}</td>
              <td>${r.racquetTotal}</td>
              <td>${r.rrpTotal}</td>
              <td>{r.discount}</td>
              <td>{r.service}</td>
              <td>{r.overgrip.toString()}</td>
              <td className='border-right-0'>{r.paid.toString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default RacquetsReport
