import { Button, Col, Dropdown, DropdownButton, Form, InputGroup, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import withFormStepWrapper from './withFormStepWrapper'
import { useState } from 'react'
import { func, object } from 'prop-types'
import BackButton from '../common/BackButton'
import { playerTypes } from '../../helpers'

const validationSchema = yup.object({
  playerId: yup.string().required().matches(/^(?!atp|wta|itf|oth|other).*/i, 'Player ID cannot start with ATP, WTA, ITF, or Other')
})

const propTypes = {
  values: object.isRequired,
  onContinue: func.isRequired,
  onBack: func.isRequired
}

const PlayerIdStep = (props) => {
  const { values, onContinue, onBack } = props
  const [showField, setShowField] = useState(values.playerId !== '')

  if (showField) {
    return <PlayerIdField {...props} />
  } else {
    return (
      <div>
        <div className='mb-3'>
          <BackButton onClick={onBack} text='Back'/>
        </div>
        <div className='mx-3'>
          <h2 className='mb-3'>Are you an ATP/WTA/ITF player?</h2>
          <p className='mb-3'>You will be asked to enter your verified player ID if you select Yes.</p>
          <Row className='flex-column gy-2'>
            <Col xs={12}>
              <Button type='button' variant='primary' className='w-100' onClick={() => setShowField(true)}>Yes</Button>
            </Col>
            <Col xs={12}>
              <Button type='button' variant='info' className='w-100' onClick={() => onContinue(values)}>No</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

PlayerIdStep.propTypes = propTypes

const PlayerIdField = withFormStepWrapper(() => {
  const formik = useFormikContext()

  return (
    <Form.Group controlId="playerId" className="mb-5">
      <h2 className='mb-3'>What is your player ID?</h2>
      <p className='mb-3'>Please enter only verified player ID. Go back to skip this step.</p>
      <InputGroup hasValidation>
      {console.log(formik.values.playerType)}
        <DropdownButton title={playerTypes[formik.values.playerType ? formik.values.playerType - 1 : 0].prefix} variant='light'>
          {[1, 2, 3, 4, 5].map(i => (
            <Dropdown.Item key={`player-type-${i}`} onClick={() => formik.setFieldValue('playerType', i)} active={formik.values.playerType === i}>{playerTypes[i - 1].title}</Dropdown.Item>
          ))}
        </DropdownButton>
        <Form.Control
          type="text"
          value={formik.values.playerId}
          onChange={formik.handleChange}
          placeholder='Enter player ID'
          onBeforeInput={formik.handleBlur}
          autoFocus
        />
        <Form.Control.Feedback type="invalid">{formik.errors.playerId}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  )
}, validationSchema)

export default PlayerIdStep
