import { useContext, useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Col, Row, Table } from 'react-bootstrap'
import TournamentDropdown from '../common/TournamentDropdown'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import useDataService from '../../hooks/useDataService'
import { DateTime } from 'luxon'

const RestringsReport = () => {
  const { tournament } = useContext(SharedDataContext)
  const { apiGetReport } = useDataService()
  const [selectedTournament, setSelectedTournament] = useState(tournament)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    if (selectedTournament) {
      apiGetReport(selectedTournament._id).then(res => {
        setData(res)
        setLoading(false)
      })
    }
  }, [selectedTournament])

  const prepareData = (data) => {
    return data.map(r => ({
      'Order ID': r.id,
      'Customer name': r.customer.fullName,
      'Stringer name': r.stringer?.fullName ?? 'Not Assigned',
      'Order date': DateTime.fromISO(r.orderDate).toLocaleString(DateTime.DATE_SHORT),
      Racquets: r.racquetCount,
      'Labour total': r.labourTotal
    }))
  }

  return (
    <>
      <Row className='mx-0 px-1 pb-3 mb-3 border-bottom justify-content-between align-items-end'>
        <Col xs='auto'>
          <h3>Restrung racquets report</h3>
        </Col>
        <Col xs='auto'>
          <CSVLink filename='restrings-by-stringer.csv' className={`btn btn-primary ${loading ? 'disabled' : ''}`} data={prepareData(data)}>Get csv</CSVLink>
        </Col>
      </Row>
      <Row className='mx-0 px-1 gy-1 mb-3 w-md-50'>
        <Col xs={12}>
          <TournamentDropdown value={selectedTournament} handleSelect={(t) => setSelectedTournament(t)} />
        </Col>
        <Col xs={12}>
          <p>The data below is a preview from {selectedTournament.name}.<br/>Please click "Get CSV" to view the entire data.</p>
        </Col>
      </Row>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th className='border-left-0'>Order ID</th>
            <th>Customer</th>
            <th>Stringer</th>
            <th>Order date</th>
            <th>Racquets</th>
            <th className='border-right-0'>Labour Total</th>
          </tr>
        </thead>
        <tbody>
          {data.slice(0, 10).map(r => (
            <tr key={r._id}>
              <td className='border-left-0'>{r.id}</td>
              <td>{r.customer?.fullName}</td>
              <td>{r.stringer?.fullName ?? 'Not Assigned'}</td>
              <td>{DateTime.fromISO(r.orderDate).toLocaleString(DateTime.DATE_SHORT)}</td>
              <td>{r.racquetCount}</td>
              <td className='border-right-0'>${r.labourTotal}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default RestringsReport
