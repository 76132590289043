import { round, cloneDeep, partition } from 'lodash'
import { FieldArray, Formik } from 'formik'
import { useContext, useState } from 'react'
import { OrderFormContext } from '../../contexts/OrderFormContext'
import { Alert, Button, Card, Col, Container, Dropdown, Form, FormControl, Row } from 'react-bootstrap'
import PlayerSelection from './player-selection/PlayerSelection'
import RacquetModal from './RacquetModal'
import RacquetCardBody from '../common/RacquetCardBody'
import Icon from '../../icons/Icon'
import ConfirmationModal from '../generic/ConfirmationModal'
import StepperInput from '../generic/StepperInput'
import { array, bool, date, object, string } from 'yup'
import useAuth from '../../hooks/useAuth'
import useDataService from '../../hooks/useDataService'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import Feedback from 'react-bootstrap/esm/Feedback'
import { DateTime } from 'luxon'
import TotalsRow from '../common/TotalsRow'
import { ROLE } from '../../helpers'
import DraftModePromoCodeFieldWrapper from './promo-code/DraftModePromoCodeFieldWrapper'
import { RacquetModel } from '../../classes/RacquetModel'

const OrderDetailsStep = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const { setBanner, tournament } = useContext(SharedDataContext)
  const { draft, setDraft, savedDraft, setSavedDraft, next, edit, refreshRacquets } = useContext(OrderFormContext)
  const { apiGetPreviousOrder, apiCreateOrder, apiUpdateOrder, apiCreateLog } = useDataService()
  const [modalData, setModalData] = useState({ show: false })
  const [removalModalData, setRemovalModalData] = useState({ show: false })
  const [showRemoveAllModal, setShowRemoveAllModal] = useState(false)

  const customerSchema = object({
    new: bool().required(),
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    playerId: string().notRequired().matches(/^(?!atp|wta|itf|oth|other).*/i, 'Player ID cannot start with ATP, WTA, ITF, or Other'),
    email: string().notRequired().email('Please enter a valid email'),
    phone: string().notRequired().matches(/^[+]?[0-9]+$/, { message: 'Invalid phone number', excludeEmptyString: true })
  }).test('require-one-of-three', 'Enter the player ID, email, or phone number.', function ({ new: newCustomer, playerId, email, phone }) {
    return !newCustomer || playerId || email || phone
  })

  const orderSchema = object({
    customer: [ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role) ? customerSchema : object(),
    dueDate: edit ? date().required('Enter the due date') : date().required('Enter the due date').min(new Date(), 'Please select a later pickup time.'),
    racquets: array().of(object()).required().min(1, 'Add at least one racquet to create an order')
  })

  const handleBack = (values) => {
    if (edit) {
      navigate(`${ROUTES.Orders}/${values._id}`)
    } else {
      navigate(ROUTES.Dashboard)
    }
  }

  const reloadLast = async (customer, setFieldValue) => {
    let newLength = null
    const res = await apiGetPreviousOrder({ customer, tournament: tournament._id })
    const [unknownRacquets, knownRacquets] = partition(res.racquets, r => r.model.fullName === 'Custom (User Entered)')
    if (unknownRacquets.length > 0) {
      unknownRacquets[0].model = new RacquetModel()
      newLength = knownRacquets.push(unknownRacquets[0])
    }
    setFieldValue('racquets', knownRacquets)
    if (newLength) {
      setModalData({ show: true, index: newLength - 1, validateOnMount: true })
    }
    setFieldValue('stringer', res.stringer)
  }

  const handlePreSubmit = async (values) => {
    try {
      await orderSchema.validate(values, { abortEarly: false })
    } catch (e) {
      await apiCreateLog({
        data: e.value,
        error: e.inner
      })
    }
  }

  const handleSubmit = async (values, bag) => {
    const payload = {
      ...cloneDeep(values),
      dueDate: values.dueDate.toISO(),
      stringer: values.stringer?._id,
      promoCode: values.promoCode?._id
    }
    if (payload.customer.new) {
      delete payload.customer._id
    }
    payload.racquets = payload.racquets.map((r, i) => {
      Array.from(['mains', 'crosses']).forEach(axis => {
        r[axis].strings = {
          ownStrings: r[axis].strings.ownStrings, // bool
          purchased: r[axis].strings.purchased?._id, // objectId
          own: r[axis].strings.own?._id, // objectId
          otherSpecified: r[axis].strings.otherSpecified, // bool
          other: r[axis].strings.other
        }
      })
      return r
    })
    let res
    if (edit) {
      res = await apiUpdateOrder(values._id, payload)
      setBanner('Order updated!')
      console.log(`Navigating to ${ROUTES.Order}/${values._id}`)
      navigate(`${ROUTES.Orders}/${values._id}`)
    } else {
      if (savedDraft?._id) {
        res = await apiUpdateOrder(savedDraft._id, payload)
      } else {
        res = await apiCreateOrder(payload)
      }
      if (res) {
        setSavedDraft(res)
        console.log(values.racquets[0])
        console.log(res.racquets[0])
        setDraft({ ...values, racquets: res.racquets, customer: { new: false, ...res.customer } })
        refreshRacquets()
        next()
      }
    }
  }

  const getTotalDiff = (values) => {
    return round(values.racquets.reduce((prev, curr) => prev + (curr.price * curr.quantity), 0) * (1 - (values.promoCode?.discount ?? 0)) - draft.total, 2)
  }

  return (
    <Formik
      initialValues={draft}
      enableReinitialize={true}
      validationSchema={orderSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleSubmit
      }) => (
        <Container className='px-0'>
          <Button
            type='button'
            variant='icon-wrapper'
            className='mt-1 mb-2'
            onClick={() => handleBack(values)}
          >
            <Icon icon='back' />
          </Button>
          <h2>
            { edit
              ? `Edit order #${values.id}`
              : 'Create order'
            }
          </h2>

          <h5 className='text-muted mb-5'>Customize your racquet restringing order to your preferences.</h5>
          <Form noValidate onSubmit={evt => {
            handlePreSubmit(values)
            handleSubmit(evt)
          }}>

            <Row className='mb-3'>
              <Col xs={12} className='mb-2'><h3>Tournament</h3></Col>
              <Col xs={12}>
                <h5 className='text-muted'>{tournament.name}</h5>
                <p>{tournament.location.address}</p>
              </Col>
            </Row>
            {[ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role) && <PlayerSelection />}

            <FieldArray
              name='racquets'
              validateOnChange={true}
              render={arrayHelpers => (
                <div>
                  {modalData.show && <RacquetModal
                    {...modalData}
                    handleAdd={arrayHelpers.push}
                    handleReplace={arrayHelpers.replace}
                    handleClose={() => {
                      setModalData({ show: false })
                      setFieldTouched('racquets', true)
                    }}
                  />}
                  <ConfirmationModal
                    show={removalModalData.show}
                    headerText='Delete racquet?'
                    bodyText='Are you sure you want to delete this racquet?'
                    actionButtonText='Delete'
                    handleCancel={() => setRemovalModalData({ show: false })}
                    handleConfirm={() => {
                      arrayHelpers.remove(removalModalData.index)
                      setRemovalModalData({ show: false })
                    }}
                    danger
                  />
                  <ConfirmationModal
                    show={showRemoveAllModal}
                    headerText='Delete racquets?'
                    bodyText='Are you sure you want to delete all racquets?'
                    actionButtonText='Delete all'
                    handleCancel={() => setShowRemoveAllModal(false)}
                    handleConfirm={() => {
                      setFieldValue('racquets', [])
                      setShowRemoveAllModal(false)
                    }}
                    danger
                  />
                  <Row className='justify-content-between align-items-end mb-4'>
                    <Col xs='auto'>
                      <h3>Racquets</h3>
                    </Col>
                    <Col xs='auto'>
                      {values.racquets.length > 0 && <Button
                        type='button'
                        variant='ghost'
                        className='text-small'
                        onClick={() => setShowRemoveAllModal(true)}
                      >delete all</Button>
                      }
                    </Col>
                  </Row>
                  <Row className='gy-3 mb-3'>
                    {values.racquets.map((r, i) => (
                      <Col key={i} xs={12}>
                        <Card bg='white' className='racquet-card border-0'>
                          <Card.Header className='bg-white border-0 py-2 d-flex justify-content-between align-items-center'>
                            <div>
                              <h5>{r.model.brand} {r.model.name}</h5>
                              {r.coachRacquet && <span className='text-small text-muted fst-italics'>Coach racquet</span>}
                            </div>
                            <Row className='gx-2 justify-content-between align-items-center'>
                              <Col>
                                <Button
                                  variant='icon-wrapper'
                                  onClick={() => {
                                    arrayHelpers.insert(i + 1, { ...r, name: `${r.name} copy` })
                                    setModalData({ show: true, index: i + 1 })
                                  }}
                                ><Icon icon='copy' size={24} /></Button>
                              </Col>
                              <Col>
                                <Button
                                  variant='icon-wrapper'
                                  onClick={() => setModalData({ show: true, index: i })}
                                ><Icon icon='pencil' size={24} /></Button>
                              </Col>
                              <Col>
                                <Button variant='info' className='close' onClick={() => setRemovalModalData({ show: true, index: i })}><Icon icon='close' size={24} /></Button>
                              </Col>
                            </Row>
                          </Card.Header>
                          <RacquetCardBody racquet={r} />
                          <Card.Footer className='bg-white border-0 d-flex justify-content-between align-items-end'>
                            <div>
                              <StepperInput
                                bordered
                                value={r.quantity}
                                min={1}
                                increment={() => arrayHelpers.replace(i, { ...r, quantity: r.quantity + 1 })}
                                decrement={() => arrayHelpers.replace(i, { ...r, quantity: r.quantity - 1 })}
                              />
                            </div>
                            <div>
                              <h5>${(r.quantity * r.price).toFixed(2)}</h5>
                            </div>
                          </Card.Footer>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              )} />

            {[ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role) && values.customer._id && values.racquets.length === 0 && (
              <Row className='mb-2'>
                <Col xs={12}>
                  <Button
                    type='button'
                    variant='info'
                    className='w-100 py-3'
                    disabled={values.customer.orders.length === 0}
                    onClick={() => reloadLast(values.customer._id, setFieldValue)}
                  >
                    <Icon icon='reload-clock' size={16} />
                    <span>Reload last restring</span>
                  </Button>
                </Col>
              </Row>
            )}

            <Row className='mb-5'>
              <Col xs={12}>
                <Button
                  type='button'
                  variant='info'
                  className='w-100 py-3'
                  onClick={() => {
                    setModalData({ show: true, index: undefined })
                  }}
                >
                  <Icon icon='plus' size={16} />
                  <span>Create a racquet</span>
                </Button>
              </Col>
              <Feedback type='invalid' className={errors.racquets && touched.racquets ? 'd-block mt-2' : ''}>{errors.racquets}</Feedback>
            </Row>

            { !edit && <DraftModePromoCodeFieldWrapper /> }

            <TotalsRow racquets={values.racquets} promoCode={values.promoCode} showPromoCode />

            <Row className='mb-5 justify-content-start gy-2'>
              <Col xs={12}>
                <h3 className='mb-2'>Pickup date & time</h3>
                <p>Select the date and time when the racquets should be ready for pickup.</p>
              </Col>
              <Col xs={12} md={5} lg={4}>
                {/* Date selection */}
                {/* {values.dueDate.toLocaleString(DateTime.DATETIME_SHORT)} */}
                <FormControl
                  type='date'
                  min={DateTime.now().toISODate()}
                  value={values.dueDate.toISODate()}
                  isInvalid={!!errors.dueDate}
                  onChange={e => setFieldValue('dueDate', values.dueDate.set({ ordinal: DateTime.fromJSDate(e.target.valueAsDate).get('ordinal') }))}
                />
              </Col>
              <Col xs={4} md='auto'>
                {/* Hour selection */}
                <Dropdown onSelect={eventKey => {
                  const meridiem = values.dueDate.toFormat('a')
                  const temp = DateTime.fromFormat(`${eventKey} ${meridiem}`, 'h a')
                  setFieldValue('dueDate', values.dueDate.set({ hour: temp.hour }))
                }}>
                  <Dropdown.Toggle variant='input' className={`w-100 justify-content-between ${errors.dueDate ? 'border-danger' : ''}`}>{values.dueDate.toFormat('h')}</Dropdown.Toggle>
                  <Dropdown.Menu className='bg-white mt-1 shadow-sm'>
                    {Array(12).fill(0).map((e, i) => (
                      <Dropdown.Item key={i} eventKey={i + 1} active={(i + 1).toString() === values.dueDate.toFormat('h')}>{i + 1}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xs={4} md='auto'>
                {/* Half-hour selection */}
                <Dropdown onSelect={eventKey => setFieldValue('dueDate', values.dueDate.set({ minute: Number(eventKey) }))}>
                  <Dropdown.Toggle variant='input' className={`w-100 justify-content-between ${errors.dueDate ? 'border-danger' : ''}`}>{values.dueDate.toFormat('mm')}</Dropdown.Toggle>
                  <Dropdown.Menu className='bg-white mt-1 shadow-sm'>
                    {['00', '30'].map((e) => (
                      <Dropdown.Item key={e} eventKey={e} active={e === values.dueDate.toFormat('mm')}>{e}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xs={4} md='auto'>
                {/* AM/PM selection */}
                <Dropdown onSelect={eventKey => {
                  const hour = values.dueDate.toFormat('h')
                  const temp = DateTime.fromFormat(`${hour} ${eventKey}`, 'h a')
                  setFieldValue('dueDate', values.dueDate.set({ hour: temp.hour }))
                }}>
                  <Dropdown.Toggle variant='input' className={`w-100 justify-content-between ${errors.dueDate ? 'border-danger' : ''}`}>{values.dueDate.toFormat('a')}</Dropdown.Toggle>
                  <Dropdown.Menu className='bg-white mt-1 shadow-sm'>
                    {['am', 'pm'].map((e) => (
                      <Dropdown.Item key={e} eventKey={e} active={e === values.dueDate.toFormat('a')}>{e}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Feedback type='invalid' className={errors.dueDate ? 'd-block' : 'd-none'}>{errors.dueDate}</Feedback>
              <Col xs={12}>
                <Form.Check
                  name='oneHrBeforeMatch'
                  id='oneHrBeforeMatch'
                  type='checkbox'
                  checked={values.oneHrBeforeMatch}
                  onChange={e => setFieldValue('oneHrBeforeMatch', e.target.checked)}
                  label='One hour before match'
                />
              </Col>

              <Col xs={8}>
                <h5>Restringing time</h5>
                <p>Select the the preferred restringing time.</p>
              </Col>
              <Col xs={4}>
                <Dropdown onSelect={eventKey => setFieldValue('restringTime', eventKey === 'N/A' ? undefined : eventKey)}>
                  <Dropdown.Toggle variant='input' className='w-100 justify-content-between'>{values.restringTime ?? 'N/A'}</Dropdown.Toggle>
                  <Dropdown.Menu className='bg-white mt-1 shadow-sm'>
                    {['N/A', 'AM', 'PM', 'On court'].map((e) => (
                      <Dropdown.Item key={e} eventKey={e} active={e === values.restringTime}>{e}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            <Row className='justify-content-center mb-4'>
              {edit && getTotalDiff(values) !== 0 && values.paid && (
              <Col xs={12}>
                <Alert variant='danger'>
                  <p><strong>Order total has changed.</strong>
                  {getTotalDiff(values) > 0
                    ? <span> Customer needs to pay extra <strong>${getTotalDiff(values).toFixed(2)}</strong> at pickup due to the changes.</span>
                    : <span> Customer needs to collect a refund of <strong>${Math.abs(getTotalDiff(values)).toFixed(2)}</strong> at pickup due to the changes.</span>
                  }
                  </p>
                </Alert>
              </Col>
              )}
              <Col xs={12} md='auto'>
                <Button
                  type='submit'
                  variant='primary'
                  className='w-100'
                  disabled={isSubmitting}
                >{edit ? 'Save' : 'Continue'}</Button>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </Formik>
  )
}

export default OrderDetailsStep
