import { useFormikContext } from 'formik'
import { Col, Dropdown, DropdownButton, Form, InputGroup, Row } from 'react-bootstrap'
import { playerTypes } from '../../../helpers'

const NewPlayerForm = () => {
  const { values, errors, touched, handleChange, setFieldValue, handleBlur } = useFormikContext()

  return (
    <Col xs={12}>
      <Row>
        <Form.Group as={Col} xs={12} md={6} controlId="customer.firstName" className='mb-2'>
          <Form.Control
            type="text"
            placeholder="First name"
            value={values.customer.firstName ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.customer?.firstName && touched.customer?.firstName}
          />
          <Form.Control.Feedback type="invalid">{errors.customer?.firstName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={12} md={6} controlId="customer.lastName" className='mb-2'>
          <Form.Control
            type="text"
            placeholder="Last name"
            value={values.customer.lastName ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.customer?.lastName && touched.customer?.lastName}
          />
          <Form.Control.Feedback type="invalid">{errors.customer?.lastName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={12} md={4} controlId="customer.email" className='mb-2 mb-md-0'>
          <Form.Control
            type="text"
            placeholder="player@mail.com"
            value={values.customer.email ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={(!!errors.customer?.email && touched.customer?.email) || typeof errors.customer === 'string'}
          />
          <Form.Control.Feedback type="invalid">{errors.customer?.email}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={12} md={4} controlId="customer.phone">
          <Form.Control
            type="text"
            placeholder="+614123456789"
            value={values.customer.phone ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={(!!errors.customer?.phone && touched.customer?.phone) || typeof errors.customer === 'string'}
          />
          <Form.Control.Feedback type="invalid">{errors.customer?.phone}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={12} md={4} controlId="customer.playerId" className='mb-2 mb-md-0'>
          <InputGroup hasValidation>
            <DropdownButton title={playerTypes[values.customer.playerType - 1].prefix} variant='light'>
              {[1, 2, 3, 4, 5].map(i => (
                <Dropdown.Item key={`player-type-${i}`} onClick={() => setFieldValue('customer.playerType', i)} active={values.customer.playerType === i}>{playerTypes[i - 1].title}</Dropdown.Item>
              ))}
            </DropdownButton>
            <Form.Control
              type="text"
              value={values.customer.playerId ?? ''}
              onChange={e => { e.target.value = e.target.value.toUpperCase(); handleChange(e) }}
              onBlur={handleBlur}
              placeholder='Player ID'
              isInvalid={(!!errors.customer?.playerId && touched.customer?.playerId) || typeof errors.customer === 'string'}
            />
            <Form.Control.Feedback type="invalid">{errors.customer?.playerId}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Control.Feedback type='invalid' className={typeof errors.customer === 'string' ? 'd-block' : ''}>{typeof errors.customer === 'string' ? errors.customer : ''}</Form.Control.Feedback>
      </Row>
    </Col>
  )
}

export default NewPlayerForm
