import { capitalize } from 'lodash'
import { Card, Col, Row } from 'react-bootstrap'
import { bool, object } from 'prop-types'

const propTypes = {
  racquet: object.isRequired,
  showPrices: bool
}

const RacquetCardBody = ({ racquet, showPrices }) => {
  return (
    <Card.Body>
      {['mains', 'crosses'].map(axis => (
        <Row key={`racquet-${axis}`} className='justify-content-between align-items-end mb-3'>
          <Col xs={12}>
            <p className='text-small text-muted'>{capitalize(axis)}</p>
            <Row className='justify-content-between'>
              <Col xs='auto'>
                <p className='text-body'>
                  <span className='fw-medium'>{racquet[axis].strings.own?.title ?? racquet[axis].strings.purchased?.title ?? racquet[axis].strings.other?.title},<br/></span>
                  {`${racquet[axis].tension.toFixed(2)} ${racquet.measure}`}
                  {`, ${racquet[axis].preStretch}%`}
                </p>
              </Col>
              {showPrices && (
                <Col xs='auto'>
                  <p>{racquet[axis].strings.ownStrings ? 'Free' : `$${racquet[axis].strings.purchased.halfsetPrice.toFixed(2)}`}</p>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      ))}
      <Row className='justify-content-between'>
        <Col xs={12} md={3}>
          <p className='text-small text-muted'>Knots</p>
          <p className='text-body'>{racquet.knots}</p>
        </Col>
        <Col xs={12} md={3}>
          <p className='text-small text-muted'>Stencil</p>
          <p className='text-body'>{`${capitalize(racquet.stencil.logo)}`}{racquet.stencil.logo !== 'none' && `, ${racquet.stencil.color}`}</p>
        </Col>
        <Col xs={12} md={3}>
          <p className='text-small text-muted'>Replace overgrip</p>
          <p className='text-body'>{racquet.overgrip ? 'Yes' : 'No'}</p>
        </Col>
      </Row>
      { showPrices && racquet.addOns.map(a => (
        <Row key={a._id} className='justify-content-between mt-3'>
          <Col xs='auto'>
            <p className='text-small text-muted'>{a.title}</p>
          </Col>
          <Col xs='auto'>
            <p>${a.price.toFixed(2)}</p>
          </Col>
        </Row>
      ))}
      { racquet.notes && (<Row>
        <Col xs={12}><p><span className='text-small text-muted'>Notes: </span>{racquet.notes}</p></Col>
      </Row>
      )}
    </Card.Body>
  )
}

RacquetCardBody.propTypes = propTypes

export default RacquetCardBody
