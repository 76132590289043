import { values } from 'lodash'
import * as PropTypes from 'prop-types'
import { Button, Container, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import UserIcon from '../common/UserIcon'
import ProfileField from './ProfileField'
import { useEffect, useState } from 'react'
import { string, object } from 'yup'
import Icon from '../../icons/Icon'
import useAuth from '../../hooks/useAuth'
import { SignInMethod } from 'firebase/auth'
import { parsePlayerIdPrefix, ROLE } from '../../helpers'
import PlayerIdField from './PlayerIdField'

const propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired
}

const UserProfile = ({ user, setUser }) => {
  const { getSignInMethods } = useAuth()
  const [emailEditable, setEmailEditable] = useState(false)
  const fields = [
    {
      name: 'firstName',
      title: 'First name',
      validation: object({
        firstName: string().required('First name is required')
      })
    },
    {
      name: 'lastName',
      title: 'Last name',
      validation: object({
        lastName: string().required('Last name is required')
      })
    },
    {
      name: 'playerId',
      title: 'Player ID',
      validation: object({
        playerId: string().required('Player ID is required').matches(/^(?!atp|wta|itf|oth|other).*/i, 'Player ID cannot start with ATP, WTA, ITF, or Other')
      })
    },
    {
      name: 'email',
      title: 'Email',
      validation: object({
        email: string().email('Invalid email').required('Email is required')
      })
    },
    {
      name: 'phone',
      title: 'Phone number',
      validation: object({
        phone: string().required('Phone number is required').matches(/^[+]?[0-9]+$/, { message: 'Invalid phone number', excludeEmptyString: true })
      })
    }
  ]
  const initEditing = {
    firstName: false,
    lastName: false,
    playerId: false,
    email: false,
    phone: false
  }

  const [editing, setEditing] = useState(initEditing)

  const onFieldEdit = fieldName => {
    const editingCopy = { ...editing }
    editingCopy[fieldName] = true
    setEditing(editingCopy)
  }

  const onFieldUpdate = user => {
    setEditing(initEditing)
    setUser(user)
  }

  useEffect(() => {
    if (user.email) {
      getSignInMethods(user.email).then(res => {
        setEmailEditable(res.includes(SignInMethod.EMAIL_PASSWORD))
      })
    } else {
      setEmailEditable(true)
    }
  }, [user])

  return (
    <Container className='px-5'>
      <div className='mb-3 d-flex align-items-center justify-content-between'>
        <div>
          <h2>{user.fullName}</h2>
          <span className='ms-1'>{user.role}</span>
        </div>
        <UserIcon user={user} size='lg' />
      </div>
      <ListGroup variant='flush' className='rounded'>
        {fields.map(f => {
          if (f.name === 'playerId') {
            if (user.role !== ROLE.Player) {
              return null
            } else {
              return (
                <ListGroup.Item key={f.name} className='bg-white pb-2'>
                  <div className='d-flex align-items-center justify-content-between mb-1'>
                    <p className='text-small text-muted'>{f.title}</p>
                    {editing[f.name]
                      ? <Button variant='info' className='close' onClick={ () => setEditing(initEditing) }><Icon icon='close' size={16} /></Button>
                      : (
                          <span className="d-inline-block">
                            <Button
                              variant='ghost'
                              size='sm'
                              disabled={values(editing).some(v => v === true)}
                              onClick={() => onFieldEdit(f.name)}
                            >Change</Button>
                          </span>
                        )
                    }
                  </div>
                  {editing[f.name]
                    ? <PlayerIdField
                        userId={user._id}
                        initialValue={{
                          playerType: user.playerType ?? 1,
                          playerId: user.playerId
                        }}
                        validationSchema={f.validation}
                        update={onFieldUpdate}
                      />
                    : <div className='text-truncate'><span>{user.playerId ? `${parsePlayerIdPrefix(user.playerType)}${user.playerId}` : 'None'}</span></div>
                  }
                </ListGroup.Item>
              )
            }
          }
          return (
            <ListGroup.Item key={f.name} className='bg-white pb-2'>
              <div className='d-flex align-items-center justify-content-between mb-1'>
                <p className='text-small text-muted'>{f.title}</p>
                {editing[f.name]
                  ? <Button variant='info' className='close' onClick={ () => setEditing(initEditing) }><Icon icon='close' size={16} /></Button>
                  : <OverlayTrigger trigger={['hover', 'focus']} overlay={(f.name === 'email' && !emailEditable) ? <Tooltip>This email is linked to a Google, Facebook, or Apple account.</Tooltip> : <></>}>
                      <span className="d-inline-block">
                        <Button
                          variant='ghost'
                          size='sm'
                          disabled={values(editing).some(v => v === true) || (f.name === 'email' && !emailEditable)}
                          onClick={() => onFieldEdit(f.name)}
                        >Change</Button>
                      </span>
                    </OverlayTrigger>
                }
              </div>
              {editing[f.name]
                ? <ProfileField
                    userId={user._id}
                    name={f.name}
                    placeholder={f.title}
                    initialValue={user[f.name]}
                    validationSchema={f.validation}
                    update={onFieldUpdate}
                  />
                : <div className='text-truncate'><span>{user[f.name] ?? 'None'}</span></div>
              }
            </ListGroup.Item>
          )
        })}
      </ListGroup>
    </Container>
  )
}

UserProfile.propTypes = propTypes

export default UserProfile
