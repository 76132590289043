import { Formik } from 'formik'
import { func, object, string } from 'prop-types'
import { Button, Col, Dropdown, DropdownButton, Form, InputGroup, Row } from 'react-bootstrap'
import useDataService from '../../hooks/useDataService'
import { useContext, useState } from 'react'
import AuthorizationCodeModal from '../common/AuthorizationCodeModal'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import { playerTypes } from '../../helpers'

const propTypes = {
  userId: string.isRequired,
  initialValue: object,
  validationSchema: object.isRequired,
  update: func.isRequired
}

const PlayerIdField = ({ userId, initialValue, validationSchema, update }) => {
  const { user } = useContext(SharedDataContext)
  const { apiGetUpdateAuthorization, apiUpdateUser } = useDataService()
  const [showModal, setShowModal] = useState(false)

  const handleSave = async (values, bag) => {
    bag.setSubmitting(true)

    if (user._id === userId) {
      const res = await apiUpdateUser(userId, values)
      if (res) {
        update(res)
      }
    } else {
      const res = await apiGetUpdateAuthorization(userId, values)
      if (res.ok) {
        setShowModal(true)
      }
    }
  }

  const handleUpdateSuccess = (user) => {
    if (user) {
      update(user)
    }
    setShowModal(false)
  }

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleSubmit
      }) => (
        <Form noValidate onSubmit={handleSubmit} className='w-100 profile-field-form'>
          <AuthorizationCodeModal
            userId={userId}
            show={showModal}
            onSuccess={handleUpdateSuccess}
            onHide={() => setShowModal(false)}
          />
          <Row>
            <Form.Group as={Col} controlId="playerId">
              <InputGroup hasValidation>
                <DropdownButton title={playerTypes[values.playerType ? values.playerType - 1 : 0].prefix} variant='light'>
                  {[1, 2, 3, 4, 5].map(i => (
                    <Dropdown.Item key={`player-type-${i}`} onClick={() => setFieldValue('playerType', i)} active={values.playerType === i}>{playerTypes[i - 1].title}</Dropdown.Item>
                  ))}
                </DropdownButton>
                <Form.Control
                  type="text"
                  value={values.playerId}
                  onChange={handleChange}
                  placeholder='Player ID'
                  isInvalid={!!errors.playerId && touched.playerId}
                  autoFocus
                />
                <Form.Control.Feedback type="invalid">{errors.playerId}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Col xs={12} md='auto' className='mt-1 mt-md-0'>
              <Button
                className='w-100'
                type='submit'
                variant='primary'
                disabled={isSubmitting}
              >Save</Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

PlayerIdField.propTypes = propTypes

export default PlayerIdField
