import { find } from 'lodash'
import useDataService from '../../hooks/useDataService'
import { useEffect, useRef, useState } from 'react'
import { Col, Dropdown, FormControl, Row } from 'react-bootstrap'
import Feedback from 'react-bootstrap/esm/Feedback'
import UserIcon from './UserIcon'
import { array, bool, func, number } from 'prop-types'
import useRootClose from 'react-overlays/useRootClose'
import { parsePlayerIdPrefix, ROLE } from '../../helpers'

const UserSearch = ({ roles, onUserSelected, isInvalid, limit, onNewUserRequested }) => {
  const { apiGetUsers } = useDataService()
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState([])
  const ref = useRef()

  useEffect(() => {
    if (search.length === 0) {
      setUsers([])
    }
    const delay = setTimeout(async () => {
      if (search.length > 0) {
        const res = await apiGetUsers({ role: roles, searchField: 'name', searchValue: search })
        setUsers(res)
        setShow(res.length > 0)
      }
    }, 300)

    return () => clearTimeout(delay)
  }, [search])

  const handleSelect = (eventKey) => {
    if (eventKey === 'new') {
      onNewUserRequested()
    } else {
      const c = find(users, p => p._id === eventKey)
      onUserSelected(c)
    }
    setShow(false)
  }

  const handleRootClose = event => {
    event.stopPropagation()
    event.preventDefault()
    setShow(false)
  }

  useRootClose(ref, handleRootClose, { disabled: !show })

  return (
    <Col xs={12}>
      <Dropdown show={show} onSelect={handleSelect}>
        <FormControl
          type="text"
          placeholder='Start typing the name...'
          onClick={() => setShow(true)}
          onChange={e => setSearch(e.target.value)}
          isInvalid={isInvalid && !show}
        />
        <Feedback type='invalid'>Select a customer</Feedback>
        <Dropdown.Menu ref={ref} className='w-100 shadow-lg mt-1 bg-white' id='player-search' style={{ maxHeight: '40vh', overflowY: 'scroll' }}>
          {users.map(u => (
            <Dropdown.Item as={Row} key={u._id} eventKey={u._id} className='d-flex ms-0 py-1 px-3 justify-content-end'>
              <Col xs={2} md={1}>
                <UserIcon user={u} size='sm' />
              </Col>
              <Col xs={10} md={11} className='align-self-center'>
                <span>{u.firstName} {u.lastName}</span>
                {u.role === ROLE.Player
                  ? <span className='ms-2 text-muted'>{u.playerId && 'ID:'} {parsePlayerIdPrefix(u.playerType)}{u.playerId}</span>
                  : <span className='ms-2 text-muted'>{u.role}</span>
                }
              </Col>
              <Col xs={10} md={11}>
                <Row>
                  <Col xs={12} md='auto'>
                    <span className='text-muted'>{u.email}</span>
                  </Col>
                  <Col xs={12} md='auto'>
                    <span className='text-muted'>{u.phone}</span>
                  </Col>
                </Row>
              </Col>
            </Dropdown.Item>
          ))}
          {onNewUserRequested &&
            <Dropdown.Item eventKey='new' className='ms-0 ps-5 py-1 px-3'>
              <u>+ Add new user</u>
            </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  )
}

const propTypes = {
  roles: array.isRequired,
  onUserSelected: func.isRequired,
  limit: number.isRequired,
  isInvalid: bool,
  onNewUserRequested: func
}

UserSearch.propTypes = propTypes

export default UserSearch
