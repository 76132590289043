import { Button, Col, Form, Row } from 'react-bootstrap'
import { useContext } from 'react'
import { ROUTES } from '../../routes'
import { useNavigate } from 'react-router-dom'
import { OrderFormContext } from '../../contexts/OrderFormContext'
import { DateTime } from 'luxon'
import RacquetAccordion from '../order-view/RacquetAccordion'
import useDataService from '../../hooks/useDataService'
import Icon from '../../icons/Icon'
import { Formik } from 'formik'
import { PAYMENT_METHOD } from '../../helpers'
import TotalsRow from '../common/TotalsRow'

const AdminCheckout = () => {
  const navigate = useNavigate()
  const { apiConfirmOrder } = useDataService()
  const { savedDraft, previous } = useContext(OrderFormContext)

  const handleSubmit = async (values, bag) => {
    bag.setSubmitting(true)
    await apiConfirmOrder(savedDraft._id, values)
    bag.setSubmitting(false)
    navigate(`${ROUTES.Orders}/${savedDraft._id}`)
  }

  return (
    <Formik
      initialValues={{
        paymentMethod: 'eft'
      }}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Button
            type='button'
            variant='icon-wrapper'
            className='mt-1 mb-2'
            onClick={previous}
          >
            <Icon icon='back' />
          </Button>
          <h2 className='mb-5'>Checkout</h2>
          <Row>
            <Col xs={12} className='border-bottom pb-1'>
              <h5 className='mb-3'>Tournament</h5>
              <p>
                {savedDraft.tournament.name},<br />
                {savedDraft.tournament.location.address}
              </p>
            </Col>
            <Col xs={12} className='border-bottom pb-1 pt-2'>
              <h5 className='mb-3'>Customer</h5>
              <p>{savedDraft.customer.firstName} {savedDraft.customer.lastName}</p>
              <p>{savedDraft.customer.email}</p>
              <p>{savedDraft.customer.phone}</p>
            </Col>
            <Col xs={12} className='border-bottom pb-1 pt-2'>
              <h5 className='mb-3'>Pickup date & time</h5>
              <p>{DateTime.fromISO(savedDraft.dueDate).toLocaleString(DateTime.DATETIME_SHORT)}</p>
            </Col>
            <Col xs={12} className='border-bottom pb-1 pt-2'>
              <h5>Payment method</h5>
              {Object.values(PAYMENT_METHOD).slice(1).map((pm, i) => {
                if (!savedDraft.customer.playerId && pm.code === PAYMENT_METHOD.PrizeMoney.code) {
                  return null
                }
                return (
                <Form.Check
                  key={i}
                  type='radio'
                  id={`paymentMethod-${pm.code}`}
                  name='paymentMethod'
                  label={pm.name}
                  value={pm.code}
                  onChange={formik.handleChange}
                  checked={formik.values.paymentMethod === pm.code}
                />
                )
              })}
            </Col>
          </Row>

          <Row className='mt-3'>
            <h3 className='mb-3'>Racquets summary</h3>
            <RacquetAccordion racquets={savedDraft.racquets} />
          </Row>

          <TotalsRow racquets={savedDraft.racquets} promoCode={savedDraft.promoCode} showPromoCode />

          <Row className='justify-content-center'>
            <Col xs={12}>
              <Button
                type='submit'
                variant='primary'
                className='w-100 mb-5'
                disabled={formik.isSubmitting}
              >
                <span id='button-text'>
                  {formik.isSubmitting ? 'Loading...' : 'Submit order'}
                </span>
              </Button>
            </Col>
          </Row>
        </Form>

      )}
    </Formik>
  )
}

export default AdminCheckout
